import Link from "next/link"
import styles from "./link-with-count.module.scss"

const LinkWithCount = ({ className = "", children, count = "", url = "#", ...rest }) => {
  return (
    <Link href={url} prefetch={false}>
      <a className={`${className} ${styles.link} flex flexYcenter`} {...rest}>
        <span className={styles.text}>{children}</span>
        {count ? <span className={styles.count}>({count})</span> : null}
      </a>
    </Link>
  )
}

export default LinkWithCount
